import { useState, useRef, useCallback } from "react";
import { Card, CardBody, Col, Row, Button } from "reactstrap"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import AddVolumePartner from './modals/AddVolumePartner';
import { Toast } from 'primereact/toast';

import moment from "moment";
import { Link } from "react-router-dom";
import { simplify, startTime, endTime, debounce, balanceFormatter, currencyFormatter } from "helpers/functions";
import { ROWS_PER_PAGE_MEDIUM_DASHBOARD } from "constants/value";
import VolumePartnerExportCSV from "./exports/VolumePartnersExportCSV";
import useVolumePartner from "hooks/useVolumePartner";
import EditVolumePartner from "./modals/EditVolumePartner";
import DeleteVolumePartner from "./modals/DeleteVolumePartner";
import { formatThousandSeparator } from "helpers/functions";
import '../../../assets/css/style.css'
import TransactionHistoryModal from "./modals/TransactionHistoryModal";

const VolumePartnersTable = ({ id, lazyState, setlazyState, selectedPeriodName }) => {
    const [search, setSearch] = useState(id || '')

    const { totalRecords, setLoading, loading, setRefresh, refresh, volumePartners } = useVolumePartner();
    const items = Array.from({ length: lazyState.config.rows }, (v, i) => {
        return {
            UCO_Approved: true
        }
    }); //prevent placeholder to have red background
    const delay = 1000;


    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = useCallback(debounce(changeFilter, delay), []);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        setLoading(true);
        debouncedSearch(value);
    }
    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }
    const badgeDirectPayment = (data) => {
        let badgeText = data.direct_payment_enabled ? "ENABLED" : "DISABLED";
        let badgeStatus = '';// Default badge text

        // Conditionally change badgeText based on status and isOpen
        if (data.direct_payment_enabled === true) {
            badgeStatus = `active`;
        } else {
            badgeStatus = `offline`;
        }
        return <>
            <span className={`badge bg-${badgeStatus} text-t-${badgeStatus}`}>
                {badgeText}
            </span>
        </>;
    }
    const badgeBonus = (data) => {
        let badgeText = data.bonus_enabled ? "Yes" : "No";
        let badgeStatus = '';// Default badge text

        // Conditionally change badgeText based on status and isOpen
        if (data.is_fixed_price === false) {
            badgeStatus = `active`;
        } else {
            badgeStatus = `offline`;
        }
        return <>
            <span className={`badge bg-${badgeStatus} text-t-${badgeStatus}`}>
                {badgeText}
            </span>
        </>;
    }

    const fixedPrice = (data) => {
        if (data.is_fixed_price) {

            return balanceFormatter(String(data?.fixed_price ?? 0), data?.user?.currency ?? 'IDR');
        } else {
            return (
                <>
                    {balanceFormatter(String(data?.fixed_price ?? 0), data?.user?.currency ?? 'IDR')}
                    <br/>
                    <span className="badge bg-primary text-t-primary">
                        App Price
                    </span>
                </>
            );

        }
    }
    const expectedVolume = (data) => {
        return formatThousandSeparator(String(data.expected_volume ?? 0));
    }
    const actualVolume = (data) => {
        return (
            <>
                <TransactionHistoryModal rowData={data} options={lazyState} type="monthly" />
                {" / "}
                {formatThousandSeparator(String(data.expected_volume ?? 0))}
            </>
        );
    }
    const collectedVolume = (data) => {
        return <TransactionHistoryModal rowData={data} options={lazyState} type="custom" />
    }

    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">
                <div>
                    <EditVolumePartner rowData={data} />
                </div>
                <div>
                    <DeleteVolumePartner rowData={data} />
                </div>
            </div>
        )
    }
    const AppUsername = (data) => {
        return <Link to={`/userlist/${data?.user?._id}`}> {data?.user?.username} </Link>
    }

    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'partner_account', header: 'Account Name', body: '', className: '' },
        { field: '', header: 'App Username', body: AppUsername, className: '' },
        { field: '', header: 'Price', body: fixedPrice, className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' } },
        { field: '', header: (<><div>Month Volume</div><div>(Actual / Exp.)</div></>), body: actualVolume, className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' } },
        { field: '', header: (<><div>Total Collected</div><div> Volume</div></>), body: collectedVolume, className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' } },
        { field: '', header: (<><div>Direct</div><div>Payment</div></>), body: badgeDirectPayment, className: '' },
        { field: '', header: (<><div>Bonus &</div><div>Promotions</div></>), body: badgeBonus, className: '' },
        { field: 'action', header: 'Action', body: adminAction },
    ]

    return (
        <Row>
            <Col xl={12}>
                <Card >
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={8} className="d-flex flex-row align-items-center gap-3">
                                <AddVolumePartner refresh={refresh} setRefresh={setRefresh} />
                            </Col>
                            <Col md={4} className="d-flex align-items-center justify-content-end flex-md-row flex-md-wrap">

                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Partner Name / Username" className='p-inputtext-sm user-filter' style={{ width: '240px' }} />
                                    {/* <InputText value={search} onChange={onSearchTextChange} placeholder="ID" className='p-inputtext-sm user-filter' style={{ width: '240px' }} /> */}
                                </span>
                            </Col>
                            <Col md={1} className="order-md-last mt-2 mt-md-0"> {/* Use order-md-last to ensure export icon comes last on large screens */}
                                {/* <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    <VolumePartnerExportCSV lazyState={lazyState} />
                                </div> */}
                            </Col>
                        </Row>


                        <DataTable
                            lazy
                            value={loading ? items : volumePartners}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE_MEDIUM_DASHBOARD}
                            size={'normal'}
                            onPage={onPage}
                            totalRecords={totalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            dataKey={loading ? '' : '_id'}
                        >
                            {/* <Column expander={true} style={{ width: '3rem' }} /> */}
                            {columns.map((col, i) => (
                                <Column
                                    key={col.field}
                                    field={col.field}
                                    header={col.header}
                                    headerClassName={col.headerClassName}
                                    style={col.style}
                                    body={loading ? <Skeleton></Skeleton> : col.body}
                                    bodyClassName={col.className}
                                />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default VolumePartnersTable